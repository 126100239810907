import React from 'react'

import {
  Button,
  Dropdown,
  DropdownSection,
  Inline,
  Lead,
  Text
} from '@te-digi/styleguide'

const DropdownCaretExample = () => (
  <Inline gap="md">
    <Dropdown trigger={<Button variant={'plain'}>Avaa</Button>}>
      <DropdownSection>
        <Lead noMargin>Osion otsikko</Lead>
        <Text
          as="p"
          color="dark"
        >
          Otsikon tarkenne
        </Text>
      </DropdownSection>
    </Dropdown>
    <Dropdown
      caret={false}
      trigger={<Button variant={'plain'}>Avaa</Button>}
    >
      <DropdownSection>
        <Lead noMargin>Osion otsikko</Lead>
        <Text
          as="p"
          color="dark"
        >
          Otsikon tarkenne
        </Text>
      </DropdownSection>
    </Dropdown>
  </Inline>
)

export { DropdownCaretExample }

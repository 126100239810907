import React from 'react'

import {
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownDivider,
  DropdownSection,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { SectionHeading } from '../../components/SectionHeading'
import { HeaderAsiointiHTMLExample } from '../../examples/html/HeaderAsiointiHTMLExample'
import { DropdownCaretExample } from '../../examples/react/DropdownCaretExample'
import { DropdownExample } from '../../examples/react/DropdownExample'
import { DropdownWidthExample } from '../../examples/react/DropdownWidthExample'
import { DropdownLanguageExample } from '../../examples/react/DropdownLanguageExample'
import { DropdownPlacementExample } from '../../examples/react/DropdownPlacementExample'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Dropdown"
    components={[
      { component: Dropdown },
      { component: DropdownDivider },
      { component: DropdownSection },
      { component: DropdownActions },
      { component: DropdownActionsItem, restElement: true }
    ]}
    status={[{ type: 'accessible', version: '16.3.0' }]}
  >
    <Section title="Peruskäyttö">
      <Paragraph>
        <Code>Dropdown</Code>-komponentille määritellään elementti, jolla se
        avataan (<Code>trigger</Code>). Komponentin sisällä voidaan määritellä
        alueita (<Code>DropdownSection</Code>), erotella alueita toisistaan (
        <Code>DropdownDivider</Code>) ja käyttää erityisesti dropdownin
        sisäiseen käyttöön tyyliteltyjä toimintoja (<Code>DropdownActions</Code>{' '}
        ja <Code>DropdownActionsItem</Code>).
      </Paragraph>
      <Playground
        enableOverflow
        example={DropdownExample}
      />
    </Section>
    <Section title="Leveys">
      <SectionHeading>Dropdownin leveys</SectionHeading>
      <Paragraph>
        Komponentin leveys määritellään <Code>size</Code>-propilla.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          200px (<Code>sm</Code>)
        </ListItem>
        <ListItem>
          280px (<Code>md</Code>, oletus)
        </ListItem>
        <ListItem>
          500px (<Code>lg</Code>, näytetään mobiilinäkymässä <Code>md</Code>
          -koossa)
        </ListItem>
      </List>
      <Playground
        enableOverflow
        example={DropdownWidthExample}
      />
      <SectionHeading>Triggerin leveys</SectionHeading>
      <Paragraph>
        Triggeriä on mahdollista kaventaa poistamalla caret ikoni{' '}
        <Code>caret</Code>-propilla. Käytä vain tarkkaan harkiten, esimerkiksi
        taulukoissa. Konsultoi Style Guidea ennen propin käyttöä.
      </Paragraph>
      <Playground
        enableOverflow
        example={DropdownCaretExample}
      />
    </Section>

    <Section title="Asettelu">
      <Paragraph>
        Valikon voi määritellä avattavaksi eri puolille triggerinä toimivaa
        elementtiä käyttämällä <Code>placement</Code>-proppia.
      </Paragraph>
      <Playground
        enableOverflow
        example={DropdownPlacementExample}
      />
    </Section>

    <Section title="Kielivalikko">
      <Paragraph>
        ATMT-headerin kielivalikko on toteutettu <Code>Dropdown</Code>
        -komponentilla.
      </Paragraph>
      <Playground
        enableOverflow
        example={DropdownLanguageExample}
      />
    </Section>

    <Section title="Verkkopalvelu">
      <Paragraph>
        Verkkopalvelun puolella <Code>Dropdown</Code>-komponenttia on käytetty
        <Code>Header</Code>-komponentista löytyvissä kielivalikossa ja
        käyttäjävalikossa.
      </Paragraph>
      <Playground
        enableOverflow
        example={HeaderAsiointiHTMLExample}
        format="html"
        widePreview
      />
    </Section>
  </Content>
)

export default Page

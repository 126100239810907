import React from 'react'

import {
  ArrowRightIcon,
  BulletFillIcon,
  Button,
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownDivider,
  DropdownSection,
  Inline,
  Lead,
  Text
} from '@te-digi/styleguide'

const DropdownExample = () => (
  <Inline gap="md">
    <Dropdown trigger={<Button>Avaa</Button>}>
      <DropdownSection>
        <Lead noMargin>Osion otsikko</Lead>
        <Text
          as="p"
          color="dark"
        >
          Otsikon tarkenne
        </Text>
      </DropdownSection>
      <DropdownDivider />
      <DropdownActions>
        <DropdownActionsItem
          as="button"
          icon={<BulletFillIcon />}
          onClick={() => alert('Toiminto 1')}
        >
          Toiminto 1
        </DropdownActionsItem>
        <DropdownActionsItem
          as="button"
          icon={<BulletFillIcon />}
          onClick={() => alert('Toiminto 2')}
          subLabel={'Toiminnon kuvaus'}
        >
          Toiminto 2
        </DropdownActionsItem>
      </DropdownActions>
      <DropdownDivider />
      <DropdownActions>
        <DropdownActionsItem
          as="button"
          iconRight={<ArrowRightIcon />}
          onClick={() => alert('Toiminto 3')}
        >
          Toiminto 3
        </DropdownActionsItem>
      </DropdownActions>
    </Dropdown>
  </Inline>
)

export { DropdownExample }

import React from 'react'

import {
  BriefcaseIcon,
  Button,
  Buttons,
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownDivider,
  DropdownSection,
  HomeIcon,
  Inline,
  Lead,
  RibbonIcon,
  Text
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const DropdownWidthExample = () => (
  <Inline gap="md">
    <Dropdown trigger={<Button>Avaa (oletus)</Button>}>
      <DropdownSection>
        <Lead noMargin>Matti Meikäläinen</Lead>
        <Text
          as="p"
          color="dark"
        >
          Henkilöasiakas
        </Text>
      </DropdownSection>
      <DropdownDivider />
      <DropdownActions>
        <DropdownActionsItem
          as={LinkMock}
          icon={<HomeIcon />}
          to="#"
        >
          Etusivu
        </DropdownActionsItem>
        <DropdownActionsItem
          as={LinkMock}
          icon={<RibbonIcon />}
          to="#"
        >
          Osaamisprofiili
        </DropdownActionsItem>
        <DropdownActionsItem
          as="button"
          icon={<BriefcaseIcon />}
          onClick={() => alert('Click!')}
        >
          Työpaikat
        </DropdownActionsItem>
      </DropdownActions>
      <DropdownDivider />
      <DropdownSection>
        <Buttons
          align="center"
          noMargin
        >
          <Button
            onClick={() => {
              // This is intentional
            }}
          >
            Kirjaudu ulos
          </Button>
        </Buttons>
      </DropdownSection>
    </Dropdown>
    <Dropdown
      size="sm"
      trigger={<Button>Avaa (sm)</Button>}
    >
      <DropdownSection>
        <Lead noMargin>Matti Meikäläinen</Lead>
        <Text
          as="p"
          color="dark"
        >
          Henkilöasiakas
        </Text>
      </DropdownSection>
      <DropdownDivider />
      <DropdownActions>
        <DropdownActionsItem
          as={LinkMock}
          icon={<HomeIcon />}
          to="#"
        >
          Etusivu
        </DropdownActionsItem>
        <DropdownActionsItem
          as={LinkMock}
          icon={<RibbonIcon />}
          to="#"
        >
          Osaamisprofiili
        </DropdownActionsItem>
        <DropdownActionsItem
          as="button"
          icon={<BriefcaseIcon />}
          onClick={() => alert('Click!')}
        >
          Työpaikat
        </DropdownActionsItem>
      </DropdownActions>
      <DropdownDivider />
      <DropdownSection>
        <Buttons
          align="center"
          noMargin
        >
          <Button
            onClick={() => {
              // This is intentional
            }}
          >
            Kirjaudu ulos
          </Button>
        </Buttons>
      </DropdownSection>
    </Dropdown>
    <Dropdown
      size="lg"
      trigger={<Button>Avaa (lg)</Button>}
    >
      <DropdownSection>
        <Lead noMargin>Matti Meikäläinen</Lead>
        <Text
          as="p"
          color="dark"
        >
          Henkilöasiakas
        </Text>
      </DropdownSection>
      <DropdownDivider />
      <DropdownActions>
        <DropdownActionsItem
          as={LinkMock}
          icon={<HomeIcon />}
          to="#"
        >
          Etusivu
        </DropdownActionsItem>
        <DropdownActionsItem
          as={LinkMock}
          icon={<RibbonIcon />}
          to="#"
        >
          Osaamisprofiili
        </DropdownActionsItem>
        <DropdownActionsItem
          as="button"
          icon={<BriefcaseIcon />}
          onClick={() => alert('Click!')}
        >
          Työpaikat
        </DropdownActionsItem>
      </DropdownActions>
      <DropdownDivider />
      <DropdownSection>
        <Buttons
          align="center"
          noMargin
        >
          <Button
            onClick={() => {
              // This is intentional
            }}
          >
            Kirjaudu ulos
          </Button>
        </Buttons>
      </DropdownSection>
    </Dropdown>
  </Inline>
)

export { DropdownWidthExample }
